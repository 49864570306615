// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import Hero from "../components/about-us/hero"
import NosValeurs from "../components/about-us/nos-valeurs"
import Desktop from "../components/about-us/section2Desktop"
import HistoryMobile from "../components/about-us/history-mobile"
import MissionMobile from "../components/about-us/mission-mobile"
import PicturesMobile from "../components/about-us/pictures-mobile"
import NosValeursMobile from "../components/about-us/nos-valeurs-mobile"
import SeelkersTestimonyMobile from "../components/about-us/seelkers-testimony-mobile"
import SeelkerQuotes from "../components/about-us/seelker-quotes"

const Index = ({ data, pageContext }) => {
  const { allContentfulFooter } = data
  const langKey = pageContext.langKey

  return (
    <>
      <Helmet>
        <title>Software and consultancy dedicated to Amazon</title>
        <meta
          name="description"
          content="Discover our story and values, and what motivated us to start this adventure and help fantastic brands on Amazon"
        />
        <meta name="format-detection" content="telephone=no" />
        {langKey === "en" && <link rel="alternate" hreflang="en-us" />}
        {langKey === "fr" && <link rel="alternate" hreflang="fr" />}
      </Helmet>

      <Layout langKey={langKey} allContentfulFooter={allContentfulFooter}>
        <Hero langKey={langKey} />
        <div sx={{ display: [null, null, `none`, `none`] }}>
          <HistoryMobile langKey={langKey} />
          <PicturesMobile langKey={langKey} />
          <MissionMobile langKey={langKey} />
          <NosValeursMobile langKey={langKey} />
          <SeelkersTestimonyMobile langKey={langKey} />
        </div>
        <div
          sx={{
            display: [`none`, `none`, `block`, `block`],
            marginBottom: `5rem`,
          }}
        >
          <Desktop langKey={langKey} />
          <NosValeurs langKey={langKey} />
          <SeelkerQuotes langKey={langKey} />
        </div>
      </Layout>
    </>
  )
}

export default Index

export const data = graphql`
  query AboutSeelkQuery($langKey: String!) {
    allContentfulFooter(
      filter: { node_locale: { eq: $langKey }, agencyServices: { ne: null } }
    ) {
      edges {
        node {
          node_locale
          seelkOffices {
            officeCity
            officeAddress
            officeTelephoneNumber
          }
          saasForAmazon
          agencyServices
          aboutSeelk
          copyrights
          contact
          connect
        }
      }
    }
  }
`
